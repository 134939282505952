<template>
    <main-layout>
        <div class="home">

            <widget title="Ana Sayfa">


            </widget>

            <!--            <widget title="Bu bir başlıktır">-->


            <!--                <img alt="Vue logo" src="../assets/logo.png">-->

            <!--                <hr/>-->
            <!--                <button class="btn btn-default">Normal</button>-->
            <!--                <button class="btn btn-default focus">Hover</button>-->
            <!--                <button class="btn btn-default active">Active</button>-->
            <!--                <button class="btn btn-default" disabled>Disabled</button>-->
            <!--                <hr/>-->
            <!--                <button class="btn btn-primary">Normal</button>-->
            <!--                <button class="btn btn-primary focus">Hover</button>-->
            <!--                <button class="btn btn-primary active">Active</button>-->
            <!--                <button class="btn btn-primary" disabled>Disabled</button>-->

            <!--                <hr/>-->
            <!--                <button class="btn btn-primary btn-flat">Normal</button>-->
            <!--                <button class="btn btn-primary btn-flat focus">Hover</button>-->
            <!--                <button class="btn btn-primary btn-flat active">Active</button>-->
            <!--                <button class="btn btn-primary btn-flat" disabled>Disabled</button>-->
            <!--                <hr/>-->
            <!--                <button class="btn btn-success">Normal</button>-->
            <!--                <button class="btn btn-success focus">Hover</button>-->
            <!--                <button class="btn btn-success active">Active</button>-->
            <!--                <button class="btn btn-success" disabled>Disabled</button>-->
            <!--                <hr/>-->
            <!--                <button class="btn btn-success btn-flat">Normal</button>-->
            <!--                <button class="btn btn-success btn-flat focus">Hover</button>-->
            <!--                <button class="btn btn-success btn-flat active">Active</button>-->
            <!--                <button class="btn btn-success btn-flat" disabled>Disabled</button>-->
            <!--                <hr/>-->
            <!--                <button class="btn btn-info">Normal</button>-->
            <!--                <button class="btn btn-info focus">Hover</button>-->
            <!--                <button class="btn btn-info active">Active</button>-->
            <!--                <button class="btn btn-info" disabled>Disabled</button>-->
            <!--                <hr/>-->
            <!--                <button class="btn btn-info btn-flat">Normal</button>-->
            <!--                <button class="btn btn-info btn-flat focus">Hover</button>-->
            <!--                <button class="btn btn-info btn-flat active">Active</button>-->
            <!--                <button class="btn btn-info btn-flat" disabled>Disabled</button>-->
            <!--                <hr/>-->
            <!--                <button class="btn btn-warning">Normal</button>-->
            <!--                <button class="btn btn-warning focus">Hover</button>-->
            <!--                <button class="btn btn-warning active">Active</button>-->
            <!--                <button class="btn btn-warning" disabled>Disabled</button>-->
            <!--                <hr/>-->
            <!--                <button class="btn btn-warning btn-flat">Normal</button>-->
            <!--                <button class="btn btn-warning btn-flat focus">Hover</button>-->
            <!--                <button class="btn btn-warning btn-flat active">Active</button>-->
            <!--                <button class="btn btn-warning btn-flat" disabled>Disabled</button>-->
            <!--                <hr/>-->
            <!--                <button class="btn btn-danger">Normal</button>-->
            <!--                <button class="btn btn-danger focus">Hover</button>-->
            <!--                <button class="btn btn-danger active">Active</button>-->
            <!--                <button class="btn btn-danger" disabled>Disabled</button>-->
            <!--                <hr/>-->
            <!--                <button class="btn btn-danger btn-flat">Normal</button>-->
            <!--                <button class="btn btn-danger btn-flat focus">Hover</button>-->
            <!--                <button class="btn btn-danger btn-flat active">Active</button>-->
            <!--                <button class="btn btn-danger btn-flat" disabled>Disabled</button>-->
            <!--                <hr/>-->
            <!--                <button class="btn btn-default btn-xs">Extra Small</button>-->
            <!--                <button class="btn btn-default btn-sm">Small</button>-->
            <!--                <button class="btn btn-default">Normal</button>-->
            <!--                <button class="btn btn-default btn-lg">Large</button>-->


            <!--                <HelloWorld msg="Welcome to Your Vue.js App"/>-->

            <!--            </widget>-->


        </div>
    </main-layout>
</template>

<script>
    // @ is an alias to /src
    import HelloWorld from '@/components/HelloWorld.vue'

    export default {
        name: 'Home',
        components: {
            HelloWorld
        }
    }
</script>
