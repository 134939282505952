export const organization = {
    namespaced: true,
    state: {
        organization: JSON.parse(window.localStorage.getItem('kp_organization')),
        list: []
    },
    actions: {
        setOrganization({dispatch, commit}, {organization}) {
            commit('changeOrganization', organization);
        },
    },
    mutations: {
        changeOrganization(state, organization) {
            state.organization = organization;
            window.localStorage.setItem('kp_organization', JSON.stringify(organization));
        }
    }
}
