import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './scss/index.scss'
import 'normalize.css'
import Vuex from 'vuex'
import VueModal from '@kouts/vue-modal';
import '@kouts/vue-modal/dist/vue-modal.css';

Vue.component('Modal', VueModal);
Vue.use(Vuex)
import './components'
import vSelect from 'vue-select'
Vue.component('v-select', vSelect)
import 'vue-select/dist/vue-select.css';

Vue.config.productionTip = false



import {store} from './store';
import initAxios from './helpers/axios'
initAxios();
//


//axios.defaults.headers.common.Authorization = store.state.authentication.user.token  ? `Bearer ${store.state.authentication.user.token}` : ''

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
