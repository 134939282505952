<template>
    <div class="widget">
        <div class="widget-header">
            <h4 class="widget-title">{{title}}</h4>
        </div>
        <div class="widget-body">
            <slot></slot>
        </div>
    </div>
</template>

<script>
    export default {
        name: "WidgetComponent",
        props: ['title'],
        data() {
            return {}
        },
        created() {
            // if (!this.$store.state.organization.organization) {
            //     this.checkOrganization();
            // }
        },
        methods: {}
    }
</script>

<style scoped lang="scss">


</style>
