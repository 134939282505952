import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'

import Company from '../views/Company'
import CalculateRequest from '../views/CalculateRequest'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    // {
    //     path: '/about',
    //     name: 'About',
    //     component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
    // },
    {
        path: '/login',
        name: 'login',
        component: Login
    },
    {
        path: '/select-organization',
        name: 'SelectOrganization',
        component: Login
    },
    {
        path: '/company',
        name: 'Company',
        component: Company
    },
    {
        path: '/calculate-request',
        name: 'CalculateRequest',
        component: CalculateRequest
    },

]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});

router.beforeEach((to, from, next) => {
    // redirect to login page if not logged in and trying to access a restricted page
    const publicPages = ['/login', '/register'];
    const authRequired = !publicPages.includes(to.path);
    const loggedIn = window.localStorage.getItem('user');

    if (authRequired && !loggedIn) {
        return next('/login');
    }

    next();
})

export default router
