import axios from 'axios'

export const company = {
    namespaced: true,
    state: {
        list: [],
        selectList: []
    },
    actions: {
        fetchCompanyList({commit}, {term, page}) {
            commit('fetchCompanyList', {term, page});
        },

    },
    mutations: {
        fetchCompanyList(state, {term, page}) {
            axios.get('operation-company', {params: {term: term, page: page}})
                .then((response) => {
                    state.list = response.data.data;
                    let list = [];
                    state.list.results.forEach(item => {
                        let obj = {
                            label: item.company.companyName,
                            value: item.company.id
                        };
                        list.push(obj);
                    });
                    state.selectList = list;
                    console.log(state.selectList);
                })
        }
    }
}
