<template>
    <main-layout>
        <widget title="Yeni Hesaplama Talebi">
            <div class="block-form">
                <div class="block-from-group">
                    <label>Lütfen hesaplama yapılmasını istediğiniz şirketi seçiniz</label>
                    <v-select @search="fetchCompany" v-model="company" :options="companies"></v-select>
                </div>
                <div class="block-form-group">
                    <button class="btn btn-success" :disabled="!company"><i class="icon icon-search"></i> Hesaplama
                        Talebi Gönder
                    </button>
                </div>
            </div>
        </widget>
    </main-layout>
</template>

<script>


    export default {
        data() {
            return {
                company: null
            }
        },
        computed: {
            companies() {
                return this.$store.state.company.selectList;
            }
        },
        created() {
            this.fetchCompany();
        },
        methods: {
            fetchCompany(search) {
                this.$store.dispatch('company/fetchCompanyList', {term: search, page: 1});
            }
        }
    };
</script>


