<template>
    <div>
        <div class="layout-left-sidebar">
            <div class="brand-wrapper">
                <img alt="Vue logo" src="../assets/logo.svg" width="220px">
            </div>

            <article class="info-box" @click="checkOrganization()">
                <span v-if="$store.state.organization.organization"> {{$store.state.organization.organization.operationName}}</span>
            </article>

            <p class="menu-label">TEŞVİK</p>
            <ul class="left-menu-list">
                <li>
                    <router-link :to="{name:'CalculateRequest'}"><i class="icon icon-searchzoomin"></i> <span>Yeni Hesaplama Talebi</span>
                    </router-link>
                </li>

                <!--                <li><a href=""><i class="icon icon-clock"></i> <span>Geçmiş Hesaplamlar</span></a></li>-->
                <!--                <li><a href=""><i class="icon icon-shoppingcartadded"></i> <span>Onaylananlar</span></a></li>-->
            </ul>

            <p class="menu-label">CARİLER</p>
            <ul class="left-menu-list">
                <li>
                    <router-link :to="{name:'Company'}"><i class="icon icon-list"></i> <span>Firma Listesi</span>
                    </router-link>
                </li>
            </ul>

            <!--            <p class="menu-label">YÖNETİM</p>-->
            <!--            <ul class="left-menu-list">-->
            <!--                <li class="is-active"><a href=""><i class="icon icon-gear"></i> <span>Hesabım</span></a></li>-->
            <!--            </ul>-->
            <!--            <p class="menu-label">MÜŞTERİLER</p>-->
            <!--            <ul class="left-menu-list">-->
            <!--                <li><a href=""><span>Hesabım</span></a></li>-->
            <!--                <li><a href=""><span>Hesabım</span></a></li>-->
            <!--                <li><a href=""><span>Hesabım</span></a></li>-->
            <!--                <li><a href=""><span>Hesabım</span></a></li>-->
            <!--            </ul>-->
            <!--            <p class="menu-label">DESTEK</p>-->

        </div>
        <div class="layout-container">
            <slot></slot>
        </div>

        <Modal v-model="showOrganizationModal" title="Organizasyon Seçimi" :enableClose="false" style="width: 100%;"
               wrapperClass="kModal">
            <p>Devam etmek için lütfen organizasyon seçimi yapınız.</p>

            <ul class="select-list">
                <li v-for="org in organizationList" :key="org.id">
                    <button class="btn btn-block btn-flat btn-primary" @click="handleSelectOrganizationName(org)">
                        {{org.operationName}}
                    </button>
                </li>
            </ul>

        </Modal>

    </div>
</template>

<script>
    export default {
        name: "LayoutComponent",
        data() {
            return {
                organization: null,
                showOrganizationModal: false,
                organizationList: []
            }
        },
        created() {
            if (!this.$store.state.organization.organization) {
                this.checkOrganization();
            }
        },
        methods: {
            checkOrganization() {

                this.$http.get('operation-user-my-list')
                    .then((response) => {
                        this.organizationList = response.data.data;
                        this.showOrganizationModal = true;
                    })
            },
            handleSelectOrganizationName(org) {
                this.$store.dispatch('organization/setOrganization', {organization: org});
                this.showOrganizationModal = false;
            },

        }
    }
</script>

<style scoped lang="scss">

    .modalClass {
        min-width: 700px;
    }

</style>
