import axios from 'axios'
// import router from '../router'
import Vue from 'vue'
import {store} from '../store'

export default () => {

    axios.defaults.headers.common.Authorization = store.state.authentication.user ? `Bearer ${store.state.authentication.user.token}` : ''

    axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL
    // axios.defaults.headers.common['X-Organization'] = organizationId
    axios.interceptors.request.use(
        (config) => {

            let organization = JSON.parse(window.localStorage.getItem('kp_organization'));
            let organizationId = null;
            if (organization) {
                if (Object.keys(organization).length > 0) {
                    organizationId = organization.id;
                }
            }

            if (config.method === 'post') {
                config.data['_organization'] = organizationId;
            }

            if (config.method === 'get') {
                if (config.params === undefined) {
                    config.params = {};
                }
                if (!config.params.hasOwnProperty('operation')) {
                    config.params['operation'] = organizationId;
                }
            }

            document.body.classList.add('loading-indicator');
            return config
        },
        (error) => {
            return Promise.reject(error)
        }
    );
    axios.interceptors.response.use(
        (response) => {
            document.body.classList.remove('loading-indicator')
            return response
        },
        (error) => {
            document.body.classList.remove('loading-indicator')
            const {status} = error.response
            if (status === 401) {
                store.dispatch('authentication/logout')
                router.push('/login')
            }
            return Promise.reject(error.message)
        }
    );
    Vue.prototype.$http = axios
}
