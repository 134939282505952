<template>
    <div class="login-wrapper">

        <div class="login-box">
            <div class="login-left">
                <form action="#" autocomplete="off">
                    <div>
                        <img alt="Kolay Panel Logo" src="../assets/logo.svg" width="300px" >
                    </div>
                    <h1>Hoş Geldiniz</h1>
                    <p> KolayPanel üyeliği için kullandığınız mail
                        adresiniz ve şifreniz ile hesabınıza giriş
                        yapabilirsiniz.</p>
                    <div>
                        <label for="email"> E Posta Adresiniz</label>
                        <input type="email" name="email" class="input-email" v-model="username" autocomplete="off"/>
                    </div>
                    <div>
                        <label for="password"> Şifreniz </label>
                        <input type="password" name="password" class="input-password" v-model="password"/>
                    </div>
                    <div>
                        <input type="button" value="Giriş Yap" class="btn" @click="handleSubmit"/>
                        <p>Bir hesabınız yok mu ? <a>Hemen kaydolun</a></p>
                    </div>
                </form>
            </div>
            <div class="login-right">
                <div class="">
                    <login-products></login-products>
                    <p class="brand-text">
                        2021 - Bu web uygulamasında kullanılan içeriklerin tamamamı Kolay Teşvik (©) tarafından
                        oluşturulmuş, emek ve kullanım hakkı firmamızda bulunan özgün içeriklerdir.
                    </p>
                </div>
            </div>
        </div>


        <div style="display: none">
            <div class="alert alert-info">
                Username: test<br/>
                Password: test
            </div>
            <h2>Login</h2>
            <form @submit.prevent="handleSubmit">
                <div class="form-group">
                    <label for="username">Username</label>
                    <input type="text" v-model="username" name="username" class="form-control"
                           :class="{ 'is-invalid': submitted && !username }"/>
                    <div v-show="submitted && !username" class="invalid-feedback">Username is required</div>
                </div>
                <div class="form-group">
                    <label htmlFor="password">Password</label>
                    <input type="password" v-model="password" name="password" class="form-control"
                           :class="{ 'is-invalid': submitted && !password }"/>
                    <div v-show="submitted && !password" class="invalid-feedback">Password is required</div>
                </div>
                <div class="form-group">
                    <button class="btn btn-primary" :disabled="loggingIn" @click="">Login</button>
                    <img v-show="loggingIn"
                         src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="/>
                </div>
            </form>
        </div>

    </div>
</template>

<script>

    const LoginProducts = () => import('../components/LoginProducts');

    export default {
        data() {
            return {
                username: '',
                password: '',
                submitted: false,
            }
        },
        components:
            {'login-products': LoginProducts}
        ,
        computed: {
            loggingIn() {
                return this.$store.state.authentication.status.loggingIn;
            }
        },
        created() {
            // reset login status
            this.$store.dispatch('authentication/logout');
        },
        methods: {
            handleSubmit(e) {
                this.submitted = true;
                const {username, password} = this;
                const {dispatch} = this.$store;
                if (username && password) {
                    dispatch('authentication/login', {username, password});
                }
            }
        }
    };
</script>


