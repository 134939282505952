<template>
    <main-layout>
        <div class="home">
            <widget title="Firmalar">
                <input type="text" class="form-element"
                       placeholder="Lütfen Aranacak Kelimeyi Yazınız"
                       v-model="filter.term" @change="getCompanyList"
                       @keyup.native.enter="getCompanyList"/>

                <table class="table table-hover  table-mc-light-blue" v-if="Object.keys(list).length>0">
                    <thead>
                    <tr>
                        <th>Firma Adı</th>
                        <th>İşlemler</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="company in list" :key="company.id">
                        <td>
                            {{company.company.companyName}}
                        </td>
                        <td></td>
                    </tr>
                    </tbody>
                </table>

                <div class="scene-wrapper" v-show="Object.keys(list).length<=0">
                    <p>Aradığınız kriterde sonuc yok.</p>
                    <img src="../assets/svg/scenes/scene2.svg"/>
                </div>

            </widget>
        </div>
    </main-layout>
</template>

<script>

    export default {
        name: 'Home',
        data() {
            return {
                list: [],
                filter: {
                    term: null,
                    page: 1,
                    operation: this.$store.state.organization.organization.id
                }
            }
        },
        created() {
            this.getCompanyList();
        },
        methods: {
            getCompanyList() {
                //this.filter.organization = this.$store.state.organization.organization.id;
                this.$http.get('operation-company', {
                    params: this.filter,
                }).then((response) => {
                    this.list = response.data.data.results;
                })
            }
        }
    }
</script>
